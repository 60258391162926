import { create } from 'zustand';

import {
  LicenseType,
} from '@app/types';

import {
  LicenseApi,
} from '@app/api';
import { LicenseCheckActiveResponse } from '@app/api';


export interface LicenseStoreInterface {
  active: boolean;
  detail: LicenseType | null;
  loading: boolean;
  checkActive: () => Promise<LicenseCheckActiveResponse>
};

export const useLicenseStore = create<LicenseStoreInterface>((set) => ({
  active: false,
  detail: null,
  loading: true,
  checkActive: async (): Promise<LicenseCheckActiveResponse> => {
    set({ loading: true });
    const response = await LicenseApi.checkActive();
    set({ loading: false });
    
    if (response.success === false) {
      return { active: false, detail: null };
    }
    
    set({
      active: response.data.active,
      detail: response.data.detail,
    });

    return response.data;
  }
}));
