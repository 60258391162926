import {
  ButtonBrick,
  CardDataComposition,
  QueueNextIcon,
} from '@app/components';

import { useCallContext, useListContext } from '../../context';
import { CallComponent } from '../call';
import { ListComponent } from '../list';


export const MainComponent = () => {
  const {
    callStart,
  } = useCallContext();
  
  const {
    callerListLen,
    callerList,
    operatorListLen,
  } = useListContext();

  return (
    <CardDataComposition
      title={`Операторов в сети: ${operatorListLen || 0}, Человек в очереди: ${callerListLen || 0}`}
      extra={
        <ButtonBrick
          disabled={callerListLen === 0}
          variant="contained"
          onClick={() => callStart()}
          startIcon={<QueueNextIcon />}
        >
          Начать прием
        </ButtonBrick>
      }
      loading={false}
      noData={callerList.length === 0}
    >
      <ListComponent />
      <CallComponent />
    </CardDataComposition>
  );
};
