import React from 'react';

import { CallType } from '@app/types';


export type CallStatusType = 'call' | 'feedback';

export interface MainContextInterface {
  callModalOpened: boolean;
  noteDrawerOpened: boolean;
  callerCurrent: CallType | null;
  callStatus: CallStatusType;
  callerCurrentSet: React.Dispatch<React.SetStateAction<CallType | null>>;
  callModalOpen: () => void;
  callModalClose: () => void;
  noteDrawerOpen: () => void;
  noteDrawerClose: () => void;
  feedbackFormOpen: () => void;
};


export const useMainHook = (
): MainContextInterface => {
  const [ callModalOpened, callModalOpenedSet ] = React.useState(false);
  const [ callerCurrent, callerCurrentSet ] = React.useState<CallType | null>(null);
  const [ noteDrawerOpened, noteDrawerOpenedSet ] = React.useState(false);
  const [ callStatus, callStatusSet ] = React.useState<CallStatusType>('call');

  const callModalOpen = React.useCallback(() => {
    callModalOpenedSet(true);
  }, []);

  const callModalClose = React.useCallback(() => {
    callModalOpenedSet(false);
    callStatusSet('call');
  }, []);

  const noteDrawerOpen = React.useCallback(() => {
    noteDrawerOpenedSet(true);
  }, []);

  const noteDrawerClose = React.useCallback(() => {
    noteDrawerOpenedSet(false);
  }, []);

  const feedbackFormOpen = React.useCallback(() => {
    callStatusSet('feedback');
  }, []);

  return React.useMemo(() => ({
    callerCurrent,
    noteDrawerOpened,
    callModalOpened,
    callStatus,
    callerCurrentSet,
    noteDrawerOpen,
    noteDrawerClose,
    callModalOpen,
    callModalClose,
    feedbackFormOpen,
  }), [
    callerCurrent,
    noteDrawerOpened,
    callModalOpened,
    callStatus,
    noteDrawerOpen,
    noteDrawerClose,
    callModalOpen,
    callModalClose,
    feedbackFormOpen,
  ]);
};
