import { MainComponent } from './components';
import { MainProvider } from './context';
import { ConnectionProvider } from './context/connection';

export const ConsultationOneFeature = () => {
  return (
    <MainProvider>
      <ConnectionProvider>
        <MainComponent />
      </ConnectionProvider>
    </MainProvider>
  );
};
