import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import {
  AdminGuardHoc,
  AnonGuardHoc,
  AuthGuardHoc,
  CallListGeneralPage,
  CallListMissedPage,
  CallListMyPage,
  CallsPage,
  ConsultationCreatePage,
  ConsultationListPage,
  ConsultationOnePage,
  HomePage,
  LicenseExpiredPage,
  LicenseGuardHoc,
  LicensePage,
  NotFoundPage,
  SettingsPage,
  SigninPage,
  UserCreatePage,
  UserListPage,
  UserOnePage,
} from '@app/components';


export const RouterOutletPart = () => {
  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
    { path: '/settings', element: <AuthGuardHoc><LicenseGuardHoc><SettingsPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },
    { path: '/user-list/create', element: <AuthGuardHoc><AdminGuardHoc><LicenseGuardHoc><UserCreatePage /></LicenseGuardHoc></AdminGuardHoc></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><AdminGuardHoc><LicenseGuardHoc><UserOnePage /></LicenseGuardHoc></AdminGuardHoc></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><AdminGuardHoc><LicenseGuardHoc><UserListPage /></LicenseGuardHoc></AdminGuardHoc></AuthGuardHoc> },
    { path: '/calls', element: <AuthGuardHoc><LicenseGuardHoc><CallsPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/call-list-general', element: <AuthGuardHoc><AdminGuardHoc><LicenseGuardHoc><CallListGeneralPage /></LicenseGuardHoc></AdminGuardHoc></AuthGuardHoc> },
    { path: '/call-list-my', element: <AuthGuardHoc><LicenseGuardHoc><CallListMyPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/call-list-missed', element: <AuthGuardHoc><LicenseGuardHoc><CallListMissedPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/license-expired', element: <AuthGuardHoc><LicenseExpiredPage /></AuthGuardHoc> },
    { path: '/license', element: <AuthGuardHoc><LicensePage /></AuthGuardHoc> },
    { path: '/consultation-list', element: <AuthGuardHoc><LicenseGuardHoc><ConsultationListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/consultation-list/create', element: <AuthGuardHoc><LicenseGuardHoc><ConsultationCreatePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/consultation-list/:uuid', element: <AuthGuardHoc><LicenseGuardHoc><ConsultationOnePage /></LicenseGuardHoc></AuthGuardHoc> },

    { path: '*', element: <AuthGuardHoc><NotFoundPage /></AuthGuardHoc> },
  ]);

  return (
    <RouterProvider router={router} />
  );
};
