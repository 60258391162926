import { create } from 'zustand';

import { BreadcrumbType } from '@app/types';


export interface BreadcrumbsStoreInterface {
  breadcrumbs: BreadcrumbType[];
  breadcrumbsHomeSet: () => void;
  breadcrumbsSettingsSet: () => void;
  breadcrumbsRoleListSet: () => void;
  breadcrumbsRoleOneSet: (id: number) => void;
  breadcrumbsRoleCreateSet: () => void;
  breadcrumbsUserListSet: () => void;
  breadcrumbsUserOneSet: (id: number) => void;
  breadcrumbsUserCreateSet: () => void;
  breadcrumbsCallsSet: () => void;
  breadcrumbsCallListGeneralSet: () => void;
  breadcrumbsCallListMySet: () => void;
  breadcrumbsCallListMissedSet: () => void;
  breadcrumbsLicenseSet: () => void;
  breadcrumbsConsultationListSet: () => void;
  breadcrumbsConsultationCreateSet: () => void;
};

export const useBreadcrumbsStore = create<BreadcrumbsStoreInterface>((set) => ({
  breadcrumbs: [],
  breadcrumbsHomeSet: () => set(() => ({
    breadcrumbs: [{ title: 'Главная', link: '/' }],
  })),
  breadcrumbsSettingsSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Настройки', link: '/' },
    ],
  })),
  breadcrumbsRoleListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/' },
    ],
  })),
  breadcrumbsRoleOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Роль №' + id, link: '/' },
    ],
  })),
  breadcrumbsRoleCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Новая роль', link: '/' },
    ],
  })),
  breadcrumbsUserListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/' },
    ],
  })),
  breadcrumbsUserOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Пользователь №' + id, link: '/' },
    ],
  })),
  breadcrumbsUserCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Новый пользователь', link: '/' },
    ],
  })),
  breadcrumbsCallsSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Звонки', link: '/calls' },
    ],
  })),
  breadcrumbsCallListGeneralSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Общий журнал звонков', link: '/' },
    ],
  })),
  breadcrumbsCallListMySet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Журнал звонков', link: '/' },
    ],
  })),
  breadcrumbsCallListMissedSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пропущенные звонки', link: '/' },
    ],
  })),
  breadcrumbsLicenseSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Лицензия', link: '/' },
    ],
  })),
  breadcrumbsConsultationListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Консультации', link: '/' },
    ],
  })),
  breadcrumbsConsultationCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Консультации', link: '/consultation-list' },
      { title: 'Новая консультация', link: '/' },
    ],
  })),
}));
