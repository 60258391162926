import React from 'react';

import {
  useAuthStore,
  useLicenseStore,
} from '@app/stores';

import {
  RouterOutletPart,
  ThemeProviderPart,
  NotifyPart,
  LoaderPart,
  LocalizationProviderPart,
} from './parts';


export const App = () => {
  const { signinCurrent } = useAuthStore();
  const { checkActive } = useLicenseStore();

  React.useEffect(() => {
    checkActive();
  }, [
    checkActive,
  ]);

  React.useEffect(() => {
    signinCurrent();
  }, [
    signinCurrent,
  ]);

  return (
    <LocalizationProviderPart>
      <ThemeProviderPart>
        <RouterOutletPart />
        <NotifyPart />
        <LoaderPart />
      </ThemeProviderPart>
    </LocalizationProviderPart>
  );
}
