import React from 'react';

import { callSocket } from '@app/api';
import { PersistanceService } from '@app/services';
import { ACTIONS_CALL } from '@app/api/call/actions';

import { useListContext } from '../list';
import { useCallContext } from '../call';
import { useNoteContext } from '../note';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    callRun,
    iceCandidateSet,
    answerSet,
    callerCallStop,
    clientDisconnect,
  } = useCallContext();
  
  const {
    generalDataSet,
    callerListUpdate,
  } = useListContext();

  const { onDisconnect } = useNoteContext();

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.ANSWER_TO_OPERATOR_SEND, answerSet);
    return () => {callSocket.off(ACTIONS_CALL.ANSWER_TO_OPERATOR_SEND, answerSet)};
  }, [answerSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet);
    return () => {callSocket.off(ACTIONS_CALL.ICE_CANDIDATE, iceCandidateSet)};
  }, [iceCandidateSet]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_CALL_STOP, async () => {
      await onDisconnect();
      callerCallStop();
    });
    return () => {callSocket.off(ACTIONS_CALL.CALLER_CALL_STOP, callerCallStop)};
  }, [callerCallStop, onDisconnect]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CLIENT_DISCONNECT, async (event: { clientId: string }) => {
      await onDisconnect();
      clientDisconnect(event);
    });
    return () => {callSocket.off(ACTIONS_CALL.CLIENT_DISCONNECT, clientDisconnect)};
  }, [clientDisconnect, onDisconnect]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_TO_OPERATOR_SEND, callRun);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_TO_OPERATOR_SEND, callRun)};
  }, [callRun]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.CALLER_LIST_TO_OPERATOR_SEND, callerListUpdate);
    return () => {callSocket.off(ACTIONS_CALL.CALLER_LIST_TO_OPERATOR_SEND, callerListUpdate)};
  }, [callerListUpdate]);

  React.useEffect(() => {
    callSocket.on(ACTIONS_CALL.DATA_GENERAL_SEND, generalDataSet);
    return () => {callSocket.off(ACTIONS_CALL.DATA_GENERAL_SEND, generalDataSet)};
  }, [generalDataSet]);

  React.useEffect(() => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_CONNECT, { jwt: PersistanceService.getToken() });
    return () => {callSocket.emit(ACTIONS_CALL.OPERATOR_DISCONNECT, { jwt: PersistanceService.getToken() });};
  }, [
  ]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
