import React from 'react';

import { ChangeFieldEventType } from '@app/types';

import { useMainContext } from '../main';
import { useAppStore } from '@app/stores';
import { CallApi } from '@app/api';


const initialFormValue = {
  OP_noAudioCaller: false,
  OP_noVideoCaller: false,
  OP_noAudioOperator: false,
  OP_noVideoOperator: false,
};

export interface CallDoneContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean,
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: () => void;
};

export const useCallDoneHook = (
): CallDoneContextInterface => {
  const { notifyCall } = useAppStore();
  const {
    callerCurrent,
    callerCurrentSet,
    callModalClose,
  } = useMainContext();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const formReset = React.useCallback(() => {
    formValueSet(initialFormValue);
    formDirtySet(false);
    formIsSubmittingSet(false);
  }, []);

  const onSubmit = React.useCallback(async () => {
    if (callerCurrent === null) return;

    formIsSubmittingSet(true);

    const response = await CallApi.feedbackFromOperator({
      callUuid: callerCurrent.uuid,
      ...formValue,
    });

    formIsSubmittingSet(false);
    callerCurrentSet(null);
    callModalClose();
    formReset();

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Ошибка записи',
      });
    }

  }, [
    callerCurrent,
    formValue,
    callModalClose,
    callerCurrentSet,
    notifyCall,
    formReset,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
  ]);
};
