import { MainComponent } from './components';

import {
  CallDoneProvider,
  CallProvider,
  ConnectionProvider,
  DevicesProvider,
  ListProvider,
  MainProvider,
  NoteProvider,
} from './context';


export const CallsFeature = () => {
  return (
    <MainProvider>
      <ListProvider>
        <DevicesProvider>
          <CallProvider>
            <NoteProvider>
              <ConnectionProvider>
                <CallDoneProvider>
                  <MainComponent />
                </CallDoneProvider>
              </ConnectionProvider>
            </NoteProvider>
          </CallProvider>
        </DevicesProvider>
      </ListProvider>
    </MainProvider>
  );
};
