import React from 'react';

import {
  useBreadcrumbsStore,
} from '@app/stores';

import {
  LicenseFeature,
  PageBaseLayout,
} from '@app/components';


export const LicensePage = () => {
  const {
    breadcrumbs,
    breadcrumbsLicenseSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsLicenseSet();
  }, [
    breadcrumbsLicenseSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <LicenseFeature />
    </PageBaseLayout>
  );
};
