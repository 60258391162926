import React from 'react';

import { useLicenseStore } from '@app/stores';


export interface MainContextInterface {
  licenseLoading: boolean;
  licenseRefresh: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { checkActive } = useLicenseStore();
  const [ licenseLoading, licenseLoadingSet ] = React.useState(false);

  const licenseRefresh = React.useCallback(async () => {
    licenseLoadingSet(true);
    await checkActive();
    licenseLoadingSet(false);
  }, [
    checkActive
  ]);

  return React.useMemo(() => ({
    licenseLoading,
    licenseRefresh
  }), [
    licenseLoading,
    licenseRefresh,
  ]);
};
