import { Navigate } from 'react-router-dom';

import {
  LinkBrick,
  PageCenteringLayout,
  TypographyBrick,
} from '@app/components';

import { useLicenseStore } from '@app/stores';


export const LicenseExpiredPage = () => {
  const { active } = useLicenseStore();
  if (active) return <Navigate to='/' />;

  return (
    <PageCenteringLayout>
      <div style={{ display: 'block', textAlign: 'center'}}>
        <TypographyBrick variant="h6">Срок действия лицензии истек.</TypographyBrick>
        <LinkBrick to="/" label="На главную" />
      </div>
    </PageCenteringLayout>
  );
};
