import React from 'react';

import { ConsultationListFeature, PageBaseLayout } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const ConsultationListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsConsultationListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsConsultationListSet();
  }, [
    breadcrumbsConsultationListSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <ConsultationListFeature />
    </PageBaseLayout>
  );
};
