import React from 'react';

import { useAppStore } from '@app/stores';
import { callSocket, ACTIONS_CALL } from '@app/api';
import { CallType } from '@app/types';
import { PersistanceService } from '@app/services';

import { useMainContext } from '../main';
import { useDevicesContext } from '../devices';


export interface CallContextInterface {
  provideMediaRef: (id: string, node: HTMLVideoElement | null) => void;
  clientDisconnect: (event: { clientId: string }) => void;
  callStart: (callId?: number) => void;
  callRun: (event: { caller: CallType }) => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate, clientId: string }) => void;
  answerSet: (event: { answer: RTCSessionDescriptionInit, clientId: string }) => void;
  operatorCallStop: () => void;
  callerCallStop: () => void;
};

export const useCallHook = (
): CallContextInterface => {
  const { notifyCall } = useAppStore();

  const {
    callerCurrent,
    callerCurrentSet,
    callModalOpen,
    // callModalClose,
    feedbackFormOpen,
  } = useMainContext();
  
  const {
    localMediaStream,
    peerConnections,
    peerMediaElements,
    cameraDeviceIdSet,
    microphoneDeviceIdSet,
    cameraListSet,
    microphoneListSet,
  } = useDevicesContext();

  const callStop = React.useCallback(() => {
    localMediaStream.current !== null
      && localMediaStream.current.getTracks().forEach(track => track.stop());
    localMediaStream.current = null;
    callerCurrent && delete peerConnections.current[callerCurrent.clientId];
    
    // callModalClose();
    feedbackFormOpen();
    // callerCurrentSet(null);
  }, [
    callerCurrent,
    localMediaStream,
    peerConnections,
    // callModalClose,
    feedbackFormOpen,
    // callerCurrentSet,
  ]);

  const provideMediaRef = React.useCallback((id: string, node: HTMLVideoElement | null) => {
    peerMediaElements.current[id] = node;
  }, [
    peerMediaElements,
  ]);

  const clientDisconnect = React.useCallback((event: { clientId: string }) => {
    if (callerCurrent === null || callerCurrent.clientId !== event.clientId) {
      return;
    }

    notifyCall({
      type: 'info',
      message: 'Разорвано соединение с собеседником.',
    });

    callStop();
  }, [
    callerCurrent,
    notifyCall,
    callStop,
  ]);

  const callStart = React.useCallback(async (callId?: number) => {
    callModalOpen();
    
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });
    
    // устанавливаем текущие девайсы
    localMediaStream.current.getTracks().forEach((track) => {
      if (track.kind === 'audio') {
        microphoneDeviceIdSet(track.getSettings().deviceId || null);
      }

      if (track.kind === 'video') {
        cameraDeviceIdSet(track.getSettings().deviceId || null);
      }
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }

    // получение данных об девайсах
    const devices = await navigator.mediaDevices.enumerateDevices();
    const microphones = devices.filter((device) => device.kind === 'audioinput');
    const cameras = devices.filter((device) => device.kind === 'videoinput');
    microphoneListSet(microphones);
    cameraListSet(cameras);

    callSocket.emit(ACTIONS_CALL.OPERATOR_CALL_START, {
      jwt: PersistanceService.getToken(),
      callId,
    });
  }, [
    localMediaStream,
    peerMediaElements,
    callModalOpen,
    cameraDeviceIdSet,
    cameraListSet,
    microphoneDeviceIdSet,
    microphoneListSet,
  ]);

  const callRun = React.useCallback(async (event: { caller: CallType }) => {
    const { caller } = event;
    const { clientId } = caller;
    callerCurrentSet(caller)

    if (clientId in peerConnections.current) {
      return console.warn('callRun - peer already exist. ID - ', clientId);
    }

    callerCurrentSet(caller);

    peerConnections.current[clientId] = new RTCPeerConnection({
      // iceServers: freeice(),
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    peerConnections.current[clientId].onicecandidate = (ev) => {
      if (ev.candidate) {
        callSocket.emit(ACTIONS_CALL.RELAY_ICE, { iceCandidate: ev.candidate, to: clientId });
      }
    }

    let tracksNumber = 0;
    peerConnections.current[clientId].ontrack = ({streams: [remoteStream]}) => {
      tracksNumber ++;

      if (tracksNumber === 2) {
        tracksNumber = 0;
        if (peerMediaElements.current['remote']) {
          peerMediaElements.current['remote'].srcObject = remoteStream;
        } else {
          // FIX LONG RENDER IN CASE OF MANY CLIENTS
          let settled = false;
          const interval = setInterval(() => {
            if (peerMediaElements.current['remote']) {
              peerMediaElements.current['remote'].srcObject = remoteStream;
              settled = true;
            }
  
            if (settled) {
              clearInterval(interval);
            }
          }, 1000);
        }
      }
    }

    if (localMediaStream.current !== null) {
      localMediaStream.current.getTracks().forEach((track) => {
        peerConnections.current[clientId].addTrack(track, (localMediaStream.current as MediaStream));
      });
    }

    const offer = await peerConnections.current[clientId].createOffer();
    await peerConnections.current[clientId].setLocalDescription(offer);

    callSocket.emit(ACTIONS_CALL.OPERATOR_SEND_OFFER, {
      clientId,
      offer,
      jwt: PersistanceService.getToken(),
    });
  }, [
    callerCurrentSet,
    localMediaStream,
    peerConnections,
    peerMediaElements,
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate, clientId: string }) => {
    const { iceCandidate, clientId } = event;

    if (!peerConnections.current[clientId]) {
      return console.warn('iceCandidateSet - peerConnection not exist');
    }

    peerConnections.current[clientId].addIceCandidate(
      new RTCIceCandidate(iceCandidate),
    );
  }, [
    peerConnections,
  ]);

  const answerSet = React.useCallback(async (event: { answer: RTCSessionDescriptionInit, clientId: string }) => {
    if (!peerConnections.current[event.clientId]) {
      return console.warn('answerSet - peerConnection not exist');
    }
    
    await peerConnections.current[event.clientId].setRemoteDescription(
      new RTCSessionDescription(event.answer),
    );
  }, [
    peerConnections,
  ]);

  const operatorCallStop = React.useCallback(() => {
    if (callerCurrent === null) {
      console.warn('caller not defined');
      return;
    }

    callSocket.emit(ACTIONS_CALL.OPERATOR_CALL_STOP, { caller: callerCurrent });

    callStop();
  }, [
    callerCurrent,
    callStop,
  ]);

  const callerCallStop = React.useCallback(() => {
    if (callerCurrent === null) {
      console.warn('opertator not defined');
      return;
    }
    
    notifyCall({
      type: 'info',
      message: 'Собеседник закончил звонок.',
    });
    
    callStop();
  }, [
    callerCurrent,
    notifyCall,
    callStop,
  ]);

  return React.useMemo(() => ({
    provideMediaRef,
    clientDisconnect,
    callStart,
    callRun,
    iceCandidateSet,
    answerSet,
    operatorCallStop,
    callerCallStop,
  }), [
    provideMediaRef,
    clientDisconnect,
    callStart,
    callRun,
    iceCandidateSet,
    answerSet,
    operatorCallStop,
    callerCallStop,
  ]);
};
