import { HttpService } from '@app/services';
import { BaseResponseInterface } from '@app/types';

import {
  ConsultationCreateRequest,
  ConsultationCreateResponse,
  ConsultationListInFlightByUserResponse,
} from './types';


export class ConsultationApi {
  public static async create(
    payload: ConsultationCreateRequest,
  ): Promise<BaseResponseInterface<ConsultationCreateResponse>> {
    return await HttpService.sendRequest('POST', '/consultations/create', payload);
  };

  public static async listInFlightByUser(
  ): Promise<BaseResponseInterface<ConsultationListInFlightByUserResponse>> {
    return await HttpService.sendRequest('GET', '/consultations/list-in-flight-by-user');
  };
};
