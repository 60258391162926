import React from 'react';
import { useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { guestAppHost } from 'config';
import { useAppStore } from '@app/stores';
import { ACTIONS_CONSULTATIONS, consultationSocket } from '@app/api';
import { PersistanceService } from '@app/services';


export interface MainContextInterface {
  consultationUuid: string | undefined;
  consultationStart: (e: { consultationUuid: string, callerClientId: string }) => Promise<void>;
  provideMediaRef: (id: string, node: HTMLVideoElement | null) => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate }) => void;
  answerSet: (event: { answer: RTCSessionDescriptionInit }) => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { uuid: consultationUuid } = useParams();
  const { notifyCall } = useAppStore();

  const [ callerClientId, callerClientIdSet ] = React.useState<string | null>(null);

  const peerConnection = React.useRef<RTCPeerConnection | null>(null);
  const localMediaStream = React.useRef<MediaStream | null>(null);
  const peerMediaElements = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});

  const provideMediaRef = React.useCallback((id: string, node: HTMLVideoElement | null) => {
    peerMediaElements.current[id] = node;
  }, [
    peerMediaElements,
  ]);

  const mediaStart = React.useCallback(async () => {
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }

    consultationSocket.emit(
      ACTIONS_CONSULTATIONS.CONSULTANT_CONNECT,
      {
        jwt: PersistanceService.getToken(),
        consultationUuid,
      },
    );
  }, [
    consultationUuid,
  ]);

  const consultationStart = React.useCallback(async (e: { consultationUuid: string, callerClientId: string }) => {
    const { callerClientId } = e;
    
    callerClientIdSet(callerClientId);
    
    if (localMediaStream.current === null) return console.log('consultationStart - localMediaStream is null');

    peerConnection.current = new RTCPeerConnection({
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    try {
      for (const track of localMediaStream.current.getTracks()) {
        peerConnection.current.addTrack(track, localMediaStream.current);
      }
    } catch (error) {
      console.error(error);      
    }

    peerConnection.current.ontrack = ((event: RTCTrackEvent) => {
      event.track.onunmute = () => {
        // if (peerMediaElements.current['remote'] && peerMediaElements.current['remote'].srcObject) {
        //   return;
        // }

        peerMediaElements.current['remote']!.srcObject = event.streams[0];
      }
    });

    let makingOffer = false;
    peerConnection.current.onnegotiationneeded = async () => {
      try {
        makingOffer = true;
        await peerConnection.current!.setLocalDescription();
        consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_SEND_OFFER, {
          jwt: PersistanceService.getToken(),
          consultationUuid,
          offer: peerConnection.current!.localDescription,
        });
      } catch (error) {
        console.log(error);
      } finally {
        makingOffer = false;
      }
    };

    peerConnection.current.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.RELAY_ICE, {
        iceCandidate: event.candidate,
        clientId: callerClientId,
      });
    };
  }, [
    consultationUuid,
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate }) => {
    try {
      peerConnection.current!.addIceCandidate(event.iceCandidate);
    } catch (error) {
      console.log(error);
    }
  }, [
    peerConnection,
  ]);

  const answerSet = React.useCallback(async (event: { answer: RTCSessionDescriptionInit }) => {
    if (!peerConnection.current) {
      return console.warn('answerSet - peerConnection not exist');
    }
    
    await peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(event.answer),
    );
  }, [
    peerConnection,
  ]);

  const copyLink = React.useCallback(() => {
    if (consultationUuid === undefined) return;

    const link = `${guestAppHost}/consultation/${consultationUuid}`;
    copy(link);
    notifyCall({
      type: 'info',
      message: 'Скопировано, ' + link,
    });
  }, [
    consultationUuid,
    notifyCall,
  ]);
 
  React.useEffect(() => {
    copyLink();
  }, [
    copyLink,
  ]);

  React.useEffect(() => {
    mediaStart();

    return () => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.CONSULTANT_DISCONNECT, { consultationUuid });
    }
  }, [
    mediaStart,
    consultationUuid,
  ]);

  return React.useMemo(() => ({
    consultationUuid,
    consultationStart,
    provideMediaRef,
    iceCandidateSet,
    answerSet,
  }), [
    consultationUuid,
    consultationStart,
    provideMediaRef,
    iceCandidateSet,
    answerSet,
  ]);
};
