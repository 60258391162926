export const ACTIONS_CONSULTATIONS = {
  // EMIT
  CONSULTANT_CONNECT: 'CONSULTANT_CONNECT',
  CONSULTANT_DISCONNECT: 'CONSULTANT_DISCONNECT',
  CONSULTANT_SEND_OFFER: 'CONSULTANT_SEND_OFFER',
  RELAY_ICE: 'RELAY_ICE',

  // LISTEN
  CALLER_READY: 'CALLER_READY',




  // LISTEN

  // EMIT
  OFFER_TO_CALLER_SEND: 'OFFER_TO_CALLER_SEND',
  ANSWER_TO_CONSULTANT_SEND: 'ANSWER_TO_CONSULTANT_SEND',

  ICE_CANDIDATE: 'ICE_CANDIDATE',
};