import io from 'socket.io-client';

import { remoteHost } from 'config';


export const consultationSocket = io(`${remoteHost}/consultations` || '', {
  // @ts-ignore: Unreachable code error
  'force new connection': true,
  reconnectionAttempts: ('Infinity' as unknown as number),
  timeout: 10000,
  transports: ['websocket'],
});