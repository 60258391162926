import React from 'react';

import { PageBaseLayout, ConsultationCreateFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';
import { useParams } from 'react-router-dom';


export const ConsultationCreatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsConsultationCreateSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsConsultationCreateSet();
  }, [
    breadcrumbsConsultationCreateSet,
    id,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <ConsultationCreateFeature />
    </PageBaseLayout>
  );
};
