import { Navigate } from 'react-router-dom';

import { LicenseGuardProps } from './license-guard.props';
import { useLicenseStore } from '@app/stores';


export const LicenseGuardHoc = ({ children }: LicenseGuardProps) => {
  const { active } = useLicenseStore();
  
  // if (loading) return children;
  if (!active) return <Navigate to='/license-expired' />;

  return children;
}
