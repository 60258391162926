import React from 'react';

import { consultationSocket } from '@app/api';
import { PersistanceService } from '@app/services';
import { ACTIONS_CONSULTATIONS } from '@app/api/consultation';

import { useMainContext } from '../main';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    consultationUuid,
    consultationStart,
    iceCandidateSet,
    answerSet,
  } = useMainContext();

  // const {
  //   callRun,
  //   iceCandidateSet,
  //   answerSet,
  //   callerCallStop,
  //   clientDisconnect,
  // } = useCallContext();
  
  // const {
  //   generalDataSet,
  //   callerListUpdate,
  // } = useListContext();

  // const { onDisconnect } = useNoteContext();

  // React.useEffect(() => {
  //   consultationSocket.on(ACTIONS.ANSWER_TO_OPERATOR_SEND, answerSet);
  //   return () => {consultationSocket.off(ACTIONS.ANSWER_TO_OPERATOR_SEND, answerSet)};
  // }, [answerSet]);

  // React.useEffect(() => {
  //   consultationSocket.on(ACTIONS.ICE_CANDIDATE, iceCandidateSet);
  //   return () => {consultationSocket.off(ACTIONS.ICE_CANDIDATE, iceCandidateSet)};
  // }, [iceCandidateSet]);

  // React.useEffect(() => {
  //   consultationSocket.on(ACTIONS.CALLER_CALL_STOP, async () => {
  //     await onDisconnect();
  //     callerCallStop();
  //   });
  //   return () => {consultationSocket.off(ACTIONS.CALLER_CALL_STOP, callerCallStop)};
  // }, [callerCallStop, onDisconnect]);

  // React.useEffect(() => {
  //   consultationSocket.on(ACTIONS.CLIENT_DISCONNECT, async (event: { clientId: string }) => {
  //     await onDisconnect();
  //     clientDisconnect(event);
  //   });
  //   return () => {consultationSocket.off(ACTIONS.CLIENT_DISCONNECT, clientDisconnect)};
  // }, [clientDisconnect, onDisconnect]);

  // React.useEffect(() => {
  //   consultationSocket.on(ACTIONS.CALLER_TO_OPERATOR_SEND, callRun);
  //   return () => {consultationSocket.off(ACTIONS.CALLER_TO_OPERATOR_SEND, callRun)};
  // }, [callRun]);

  // React.useEffect(() => {
  //   consultationSocket.on(ACTIONS.CALLER_LIST_TO_OPERATOR_SEND, callerListUpdate);
  //   return () => {consultationSocket.off(ACTIONS.CALLER_LIST_TO_OPERATOR_SEND, callerListUpdate)};
  // }, [callerListUpdate]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet)};
  }, [iceCandidateSet]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALLER_READY, consultationStart);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALLER_READY, consultationStart)};
  }, [consultationStart]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.ANSWER_TO_CONSULTANT_SEND, answerSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.ANSWER_TO_CONSULTANT_SEND, answerSet)};
  }, [answerSet]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
