import {
  ButtonBackBlock,
  CardDataComposition,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableRowBrick,
  TableCellBrick,
  TableBodyBrick,
  ButtonLoadingBrick,
} from '@app/components';

import { useMainApi } from '../../context';
import { translateDateHelper } from '@app/helpers';
import { useLicenseStore } from '@app/stores';


export const MainComponent = () => {
  const {
    licenseLoading,
    licenseRefresh,
  } = useMainApi();

  const { active, detail } = useLicenseStore();

  return (
    <CardDataComposition
      title="Лицензия"
      extra={<ButtonBackBlock buttonIcon />}
      noData={detail === null}
    >
      {detail !== null && (
        <TableContainerBrick>
          <TableBrick>
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>Ключ</TableCellBrick>
                <TableCellBrick>Статус</TableCellBrick>
                <TableCellBrick>Дата окончания</TableCellBrick>
                <TableCellBrick></TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              <TableRowBrick sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCellBrick>{detail.key}</TableCellBrick>
                <TableCellBrick>{active ? 'Активна' : 'Не активна'}</TableCellBrick>
                <TableCellBrick>{translateDateHelper(detail.expirationDate)}</TableCellBrick>
                <TableCellBrick>
                  <ButtonLoadingBrick
                    size="small"
                    variant="contained"
                    loading={licenseLoading}
                    onClick={licenseRefresh}
                  >
                    Обновить
                  </ButtonLoadingBrick>
                </TableCellBrick>
              </TableRowBrick>
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      )}
    </CardDataComposition>
  );
};
