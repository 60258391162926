import React from 'react';

import { callSocket } from '@app/api';
import { PersistanceService } from '@app/services';
import { CallType } from '@app/types';
import { ACTIONS_CALL } from '@app/api/call';

import { useMainContext } from '../main';


export interface ListContextInterface {
  operatorListLen: number;
  callerListLen: number;
  callerList: CallType[];
  callerListUpdate: (event: any) => void;
  callReject: (callId: number) => void;
  generalDataSet: (event: any) => void;
};

let audioCtx = new AudioContext();

export const useListHook = (
): ListContextInterface => {
  const { callModalOpened } = useMainContext();

  const [ operatorListLen, operatorListLenSet ] = React.useState(0);
  const [ callerListLen, callerListLenSet ] = React.useState(0);
  const [ callerList, callerListSet ] = React.useState<CallType[]>([]);

  const beep = React.useCallback((freq: number) => {
    audioCtx.resume().then(() => {
      let oscillator = audioCtx.createOscillator(); 
      oscillator.type = 'sine'; 
      oscillator.connect(audioCtx.destination); 
      oscillator.start(); 
      oscillator.stop(audioCtx.currentTime + 0.5); 
    })
  }, []);

  const callerListUpdate = React.useCallback((event: any) => {
    if ((callerList.length < event.callerList.length) && !callModalOpened) {
      beep(700);
    }
    callerListSet(event.callerList)
  }, [
    callerList,
    callModalOpened,
    beep,
  ]);

  const callReject = React.useCallback((callId: number) => {
    callSocket.emit(ACTIONS_CALL.OPERATOR_CALL_REJECT, {
      jwt: PersistanceService.getToken(),
      callId,
    });
  }, []);

  const generalDataSet = React.useCallback((event: any) => {
    callerListLenSet(event.callerListLen);
    operatorListLenSet(event.operatorListLen);
  }, []);

  return React.useMemo(() => ({
    operatorListLen,
    callerListLen,
    callerList,
    callerListUpdate,
    callReject,
    generalDataSet,
  }), [
    operatorListLen,
    callerListLen,
    callerList,
    callerListUpdate,
    callReject,
    generalDataSet,
  ]);
};
