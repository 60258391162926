import React from 'react';

import { useAppStore } from '@app/stores';
import { ConsultationType } from '@app/types';
import { ConsultationApi } from '@app/api';


export interface MainContextInterface {
  consultationList: ConsultationType[];
  consultationListIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ consultationList, consultationListSet ] = React.useState<ConsultationType[]>([]);
  const [ consultationListIsLoading, consultationListIsLoadingSet ] = React.useState(false);

  const consultationListDownload = React.useCallback(async () => {
    consultationListIsLoadingSet(true);
    const response = await ConsultationApi.listInFlightByUser();
    consultationListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке консультаций.'
      });
    }

    consultationListSet(response.data.consultationList);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    consultationListDownload();
  }, [
    consultationListDownload,
  ]);

  return React.useMemo(() => ({
    consultationList,
    consultationListIsLoading,
  }), [
    consultationList,
    consultationListIsLoading,
  ]);
};
